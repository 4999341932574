import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './App';
import { createBrowserHistory } from 'history'
import './main.scss'; 
import * as serviceWorker from './serviceWorker';
import AOS from "aos"
import "aos/dist/aos.css"


export const history = createBrowserHistory();
 
AOS.init();
ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
